import {
  registerApplication,
  start,
  LifeCycles,
  getAppStatus,
  triggerAppChange,
  addErrorHandler,
} from "single-spa";
import * as singleSpa from "single-spa";

registerApplication({
  name: "@uni/singularlabs-mf-oauth",
  app: () => System.import<LifeCycles>("@uni/singularlabs-mf-oauth"),
  activeWhen: (location) => {
    const allowedPaths = ["/sign-in", "/register", "/forgotPassword", "/mfa"];
    return allowedPaths.some(path => location.pathname.startsWith(path) || location.pathname === "/");
  },
});

registerApplication({
  name: "@uni/kuntur-mf-cards",
  app: () => System.import<LifeCycles>("@uni/kuntur-mf-cards"),
  activeWhen: ["/cards"],
});

registerApplication({
  name: "@uni/singularlabs-mf-menu",
  app: () => System.import<LifeCycles>("@uni/singularlabs-mf-menu"),
  activeWhen: (location) =>
    !(["/sign-in", "/register", "/forgotPassword", "/mfa"].some(path => location.pathname.startsWith(path)) || location.pathname === "/"),
});

registerApplication({
  name: "@uni/kuntur-mf-payments",
  app: () => System.import<LifeCycles>("@uni/kuntur-mf-payments"),
  activeWhen: ["/payments"],
});

registerApplication({
  name: "@uni/singularlabs-mf-users",
  app: () => System.import<LifeCycles>("@uni/singularlabs-mf-users"),
  activeWhen: ["/users", "/newuser", "/roles", "/newrole", "/company", "/account-user",'/services'],
});

registerApplication({
  name: "@uni/kuntur-mf-config",
  app: () => System.import<LifeCycles>("@uni/kuntur-mf-config"),
  activeWhen: ["/config"],
});

registerApplication({
  name: "@uni/kuntur-mf-menssage",
  app: () => System.import<LifeCycles>("@uni/kuntur-mf-menssage"),
  activeWhen: ["/menssage"],
});

registerApplication({
  name: "@uni/kuntur-mf-support",
  app: () => System.import<LifeCycles>("@uni/kuntur-mf-support"),
  activeWhen: ["/support", "/ticket"],
});

registerApplication({
  name: "@uni/kuntur-mf-accounts",
  app: () => System.import<LifeCycles>("@uni/kuntur-mf-accounts"),
  activeWhen: ["/accounts", "/registerAccount", "/statement"],
});

registerApplication({
  name: "@uni/singularlabs-mf-contacts",
  app: () => System.import<LifeCycles>("@uni/singularlabs-mf-contacts"),
  activeWhen: ["/contacts", "/importContacts", "/groupsContacts"],
});

registerApplication({
  name: "@uni/singularlabs-mf-campaign",
  app: () => System.import<LifeCycles>("@uni/singularlabs-mf-campaign"),
  activeWhen: ["/campaign", "/template", "/newcampaign", "/newtemplate", "/resumecampaign"],
});


registerApplication({
  name: "@uni/singularlabs-mf-managament",
  app: () => System.import<LifeCycles>("@uni/singularlabs-mf-managament"),
  activeWhen: ["/managament", "/master"],
});

start({
  urlRerouteOnly: false,
});

export interface Microfrontend {
  mf: string;
  single: string;
}

const microfrontends: Microfrontend[] = [
  { mf: "@uni/singularlabs-mf-oauth", single: "single-spa-application:@uni/singularlabs-mf-oauth", },
  { mf: "@uni/singularlabs-mf-menu", single: "single-spa-application:@uni/singularlabs-mf-menu", },
  { mf: "@uni/singularlabs-mf-cards", single: "single-spa-application:@uni/singularlabs-mf-cards", },
  { mf: "@uni/singularlabs-mf-payments", single: "single-spa-application:@uni/singularlabs-mf-payments", },
  { mf: "@uni/singularlabs-mf-users", single: "single-spa-application:@uni/singularlabs-mf-users", },
  { mf: "@uni/singularlabs-mf-managament", single: "single-spa-application:@uni/singularlabs-mf-managament", },
  { mf: "@uni/singularlabs-mf-menssage", single: "single-spa-application:@uni/singularlabs-mf-menssage", },
  { mf: "@uni/singularlabs-mf-support", single: "single-spa-application:@uni/singularlabs-mf-support", },
  { mf: "@uni/singularlabs-mf-contacts", single: "single-spa-application:@uni/singularlabs-mf-contacts", },
];

triggerAppChange().then(() => {
  for (let o of microfrontends) {
    isDisplay(o.mf, o.single);
  }
  viewMenu();
});

window.addEventListener("single-spa:app-change", (evt) => {
  for (let o of microfrontends) {
    isDisplay(o.mf, o.single);
  }
  viewMenu();
});

function isDisplay(nameMF: string, nameId: string) {
  const status = getAppStatus(nameMF);
  const usuario = document.getElementById(nameId);
  if (usuario !== null) {
    if (
      status === null ||
      ["NOT_MOUNTED", "NOT_LOADED", "LOAD_ERROR"].includes(status)
    ) {
      //ocultando el componente no montado
      usuario.style.display = "none";
    } else {
      usuario.style.display = "block";
    }
  }
}

addErrorHandler((error) => {
  // Maneja los errores relacionados con Single-spa
  console.error("Error en Single-spa:", error);
});

// Ocultar cualquier menu cuando el login esta activo
function viewMenu() {
  const menuStatus = getAppStatus("@uni/singularlabs-mf-menu");

  const authElement = document.getElementById("single-spa-application:@uni/singularlabs-mf-oauth");
  const menuElement = document.getElementById("single-spa-application:@uni/singularlabs-mf-menu");

  // Manejar la visibilidad del formulario de autenticación
  authElement && (authElement.style.display = menuStatus === "MOUNTED" ? "none" : "block");

  // Manejar la visibilidad del menú
  menuElement && (menuElement.style.display = menuStatus === "MOUNTED" ? "block" : "none");
}
